import qs from 'qs';

// util class
export class FeatureFlag {
  static has(featKey) {
    return !!qs.parse(window.location.search, { ignoreQueryPrefix: true })[
      featKey
    ];
  }

  static enable(featKey) {
    return (
      qs.parse(window.location.search, { ignoreQueryPrefix: true })[featKey] ===
      'true'
    );
  }

  static checkEnvSwitch(envKey) {
    return process.env[envKey] === 'true';
  }
}
