import axios from 'axios';

import { toast } from 'react-toastify';

import { history } from '../App';
import { changeApi, changeReqData, changeResDataKey } from '../utils/http';

const requestToastId = 'request-toast-id';

const checkStatus = (status) => {
  if (status === 404) {
    history.push('/notfound');
  }
  if (status === 401) {
    localStorage.setItem('forceRefresh', 'true');
    const return_url = sessionStorage.getItem('returnUrl');
    let url = `/login?sessionExpired=true`;
    if (return_url) {
      url += `&return_url=${return_url}`;
    }
    history.push(url);
    toast.info('Your session has expired, please login again', {
      toastId: requestToastId
    });
  }
  if (status === 400) {
    history.push('/notfound');
  }
  if (status === 500) {
    /*  clearStorage();
     sessionStorage.clear();
     history.push('/login');
     toast.error('Server error - check the terminal for more info!', { toastId: requestToastId }); */
  }
  return true;
};

const service = axios.create({
  headers: {
    get: {
      'Content-Type':
        'application/json;application/x-www-form-urlencoded;charset=utf-8'
    },
    post: {
      'Content-Type': 'application/json;charset=utf-8'
    }
  },

  withCredentials: true,
  timeout: 120000,
  transformRequest: [
    (data) => {
      data = JSON.stringify(data);
      return data;
    }
  ],
  validateStatus(status) {
    return checkStatus(status);
  },
  transformResponse: [
    (data) => {
      if (typeof data === 'string' && data.startsWith('{')) {
        data = JSON.parse(data);
      }
      return data;
    }
  ]
});

/* service.interceptors.response.use(undefined, error => {
  console.log("interceptors",error);
  if (error.message === 'Network Error' && !error.response) {
    toast.error('Network error - make sure API is running!');
  }
  
  const { status, data, config } = error.data;
  console.log("status:->",status);
  if (status === 404) {
    history.push('/notfound');
  }
  if (status === 401) {
    console.log("status:->>>",status);
    clearStorage();
    sessionStorage.clear();
    history.push('/login');
    toast.info('Your session has expired, please login again');
  }
  if (
    status === 400 &&
    config.method === 'get' &&
    data.errors.hasOwnProperty('id')
  ) {
    history.push('/notfound');
  }
  if (status === 500) {
    toast.error('Server error - check the terminal for more info!');
  }
  throw error.response;
}); */

let pending = [];
let CancelToken = axios.CancelToken;
let removePending = (config) => {
  for (let p in pending) {
    if (pending[p].u === config.url + '&' + config.method) {
      pending[p].f();
      pending.splice(p, 1);
    }
  }
};

service.interceptors.request.use(
  (config) => {
    // console.log(new Date().getMinutes() + ':' + new Date().getSeconds() + ':' + new Date().getMilliseconds(), 'request')

    if (config.url !== '/api/products/import/importtostore') {
      removePending(config);
    }

    config.url = changeApi(config);
    config.data = changeReqData(config);

    config.cancelToken = new CancelToken((c) => {
      pending.push({ u: config.url + '&' + config.method, f: c });
    });

    return config;
  },
  (error) => {
    error.data = {};
    error.data.msg = 'Error！';
    return Promise.resolve(error);
  }
);

service.interceptors.response.use(
  (response) => {
    changeResDataKey(response);
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/* service.interceptors.response.use((response) => {
  // console.log(new Date().getMinutes() + ':' + new Date().getSeconds() + ':' + new Date().getMilliseconds(), 'response')
  // removePending(response.config)
  const status = response.status
  let msg = ''
  if (status < 200 || status >= 300 || response.data.status === false) {
 
    msg = showStatus(status)
    if (typeof response.data === 'string') {
      response.data = {msg}
    } else {
      response.data.msg = msg
    }
    // throw response
  }
  return response
}, (error) => {
  console.log('============error')
 
  error.data = {}
  error.data.msg = 'Request timeout or server exception, please check the network！'
  error.data.data = [
    {status: false}
  ]
  return Promise.resolve(error)
}) */

export default service;
