class History {
  static _history = null;
  static save(history) {
    this._history = history;
  }
  static push(...args) {
    this._history.push(...args);
  }
  static replace(...args) {
    this._history.replace(...args);
  }
}

export default History;
