import {
  FETCH_SETTINGS,
  UPDATE_SETTINGS,
  UPDATE_PRICING_RULES,
  SET_SHIPPING_RULE,
  SET_STOCK_RULE,
  SET_ORDER_RULE,
  SET_PRICING_RULE_LIST,
  CLEAR_PRICING_RULE_LIST,
  SET_FREE_SHIPPING_TAG,
  SET_AUTO_FULLFILL_ORDER,
  SET_IS_BRAND_USER,
  SET_AUTO_UPDATE_PRICE
} from './settingsConstants';
import { LISTEN_TO_CURRENT_USER_PROFILE } from '../myAccount/profileConstants';
import { SET_FEATURE_FLAG_AUTO_SYNC } from '../../reducers/ThemeOptions';
import { clearSelectedProducts, setImportProduct, clearSelectedProduct } from '../products/productActions'

import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from '../../reducers/asyncReducer';

import service from '../../services/request';

const API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL;

export const getUserProfile = () => {
  let url = 'api/users/getuserinfo';
  let pageParams = {};

  if (sessionStorage.getItem('email')) {
    pageParams['email'] = sessionStorage.getItem('email');
  }

  return async function (dispatch) {
    dispatch(asyncActionStart());

    service
      .get(url, { params: pageParams })
      .then(function (response) {
        if (response.data.status === true) {
          const userProfile = response.data.data || {};
          const userBinding = response.data.data.binding;
          const isShopifyUser = response.data.data.myshopify_domain ? true : false;
          localStorage.setItem('isShopifyUser', isShopifyUser)
          var isAutoSync =
            response.data.data.price_auto_sync === 0 ? false : true;
          localStorage.setItem('isAutoSync', isAutoSync)
          if (localStorage.getItem('f_mode3') === 'true') {
            dispatch({
              type: SET_FEATURE_FLAG_AUTO_SYNC,
              payload: isAutoSync
            });
          }
          dispatch({
            type: LISTEN_TO_CURRENT_USER_PROFILE,
            payload: { userProfile, userBinding, isAutoSync, isShopifyUser }
          });
          const isBrand = userProfile.brand === 1;
          dispatch({
            type: SET_IS_BRAND_USER,
            payload: isBrand
          });
          dispatch(asyncActionFinish());
        }
      })
      .catch(function (error) {
        console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
};

export function fetchSettings() {
  let url = 'api/setting/config';

  return async function (dispatch) {
    dispatch(asyncActionStart());

    service
      .get(url)
      .then(function (response) {
        // console.log("fetchSettings response",response);
        if (response.data.status === true) {
          const priceRules = response.data.data.price_rules;
          const shippingRule = response.data.data.shipping_rule;
          const stockRule = response.data.data.prevent_update_stock;
          const orderRule = response.data.data.order_placing_status;
          const freeShippingTag = response.data.data.free_shipping_tag;
          const autoFullfillOrder = response.data.data.order_fulfillment_status;
          const autoUpdatePrice = response.data.data.auto_update_price;
          /*      for (let i = 0; i < products.length; i++) {
            
            } */
          dispatch({ type: CLEAR_PRICING_RULE_LIST });
          dispatch({
            type: FETCH_SETTINGS,
            payload: {
              priceRules,
              shippingRule,
              stockRule,
              orderRule,
              freeShippingTag,
              autoFullfillOrder,
              autoUpdatePrice
            }
          });
          dispatch(asyncActionFinish());
        }
      })
      .catch(function (error) {
        console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
}

export function setShippingRule(value) {
  return function (dispatch) {
    dispatch({ type: SET_SHIPPING_RULE, payload: value });
  };
}

export function setValidationError(value) {
  return function (dispatch) {
    const updateSettingResponse = value;

    dispatch({
      type: UPDATE_PRICING_RULES,
      payload: { updateSettingResponse }
    });
  };
}
export function setStockRule(value) {
  return function (dispatch) {
    dispatch({ type: SET_STOCK_RULE, payload: value });
  };
}

export function setOrderRule(value) {
  return function (dispatch) {
    dispatch({ type: SET_ORDER_RULE, payload: value });
  };
}

export function setFreeShippingTag(value){
  return function (dispatch) {
    dispatch({ type: SET_FREE_SHIPPING_TAG, payload: value });
  };
}

export function setAutoFullfillOrder(value){
  return function (dispatch) {
    dispatch({ type: SET_AUTO_FULLFILL_ORDER, payload: value });
  };
}

export function setAutoUpdatePrice(value) {
  return function (dispatch) {
    dispatch({ type: SET_AUTO_UPDATE_PRICE, payload: value });
  };
}

export function setPricingRuleList(value) {
  return function (dispatch) {
    dispatch({ type: CLEAR_PRICING_RULE_LIST });
    dispatch({ type: SET_PRICING_RULE_LIST, payload: value });
  };
}

export function updatePricingRuleSettings(pricingRuleList) {
  let url = 'api/setting/savepricerules';

  /* let pageParams = {
        shipping_status: shippingRule,
        prevent_update_stock: stockRule,
        order_placing: orderRule,
        free_shipping_tag:true
    }; */

  //console.log('importProductsList', productIDsString);

  return async function (dispatch) {
    dispatch(asyncActionStart());

    //await delay(1000);
    service
      .post(url, pricingRuleList)
      .then(function (response) {
        //console.log('response -> updatePricingRuleSettings ->', response);

        if (response.data.status === true) {
          const updateSettingResponse = 'success';

          dispatch({
            type: UPDATE_PRICING_RULES,
            payload: { updateSettingResponse }
          });
          localStorage.removeItem("selectedProduct")
          dispatch(clearSelectedProducts())
          dispatch(clearSelectedProduct())
          dispatch(setImportProduct(0, true, false, false));
          dispatch(fetchSettings());
          dispatch(asyncActionFinish());
        } else {
          dispatch(asyncActionFinish());
          const updateSettingResponse = 'fail';
          const updateSettingError = response.data.data;

          dispatch({
            type: UPDATE_PRICING_RULES,
            payload: { updateSettingResponse, updateSettingError }
          });
          dispatch(asyncActionError(response.data.data));
        }
      })
      .catch(function (error) {
        console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
}

export function updateOtherSettings(shippingRule, stockRule, orderRule, freeShippingTag, autoFullfillOrder, autoUpdatePrice) {
  let url = 'api/setting/update';


  //console.log('updateOtherSettings', shippingRule, stockRule, orderRule, freeShippingTag, autoFullfillOrder, autoUpdatePrice);

  return async function (dispatch) {
    dispatch(asyncActionStart());

    //await delay(1000);
    service
      .post(url, {
        shipping_status: shippingRule,
        prevent_update_stock: stockRule,
        order_placing: orderRule,
        free_shipping_tag: freeShippingTag,
        order_fulfillment_status: autoFullfillOrder,
        auto_update_price: autoUpdatePrice
      })
      .then(function (response) {
        //console.log('response -> updateOtherSettings ->', response);

        if (response.data.status === true) {
          const updateSettingResponse = response.data.data;

          dispatch({
            type: UPDATE_SETTINGS,
            payload: { updateSettingResponse }
          });
          dispatch(asyncActionFinish());
        } else {
          dispatch(asyncActionFinish());

          const updateSettingResponse = 'fail';
          const updateSettingError = response.data.data;

          dispatch({
            type: UPDATE_SETTINGS,
            payload: { updateSettingResponse, updateSettingError }
          });

          dispatch(asyncActionError(response.data.data));
        }
      })
      .catch(function (error) {
        console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
}

export function createPriceSync(customerId, userId) {
  const url = API_GATEWAY_URL + `shopifysyncservice/v1/retailers/${customerId}/price_sync`;
  const params = {
    sellerId: userId,
    type: "markup_change"
  }
  const config = {
    withCredentials: false,
    headers: {}
  }
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = 'jwt ' + token;
  }
  return async function (dispatch) {
    service
      .post(url, params, config)
      .then(function (response) {
        if (response.data.status === true) {
          console.info(`createPriceSync success: ${JSON.stringify({ url, params, result: response.data })}`);
        } else {
          console.error(`createPriceSync failed: ${JSON.stringify({ url, params, result: response.data })}`);
        }
      })
      .catch(function (error) {
        console.error(`createPriceSync error: ${JSON.stringify({ url, params, error })}`);
      });
  };
}
